import React from "react";
import { Link } from "gatsby";
import Icon from "../images/404.svg";
import Background from "../components/background.js";
import "typeface-raleway";

import "../styles/index.css";

const NotFoundPage = () => (
	<Background>
		<div className={"w3-text-white"}>
			<div className={"full-opacity"}></div>
			<div style={{ position: `relative` }}>
				<br />
				<br />
				<br class="hd-mob" />

				<div>
					<div className={"w3-image w3-center w3-padding-large"}>
						<img className={"w3-mobile four"} src={Icon} alt="404" />
					</div>

					<hr
						className={"w3-border-white w3-section"}
						style={{
							margin: `auto`,
							width: `90%`,
							maxWidth: `500px`,
							borderWidth: `3px`,
							position: `relative`,
						}}
					/>

					<h1 className={"w3-xxlarge w3-center"}>This Page Does Not Exist</h1>
				</div>

				<br class="hd-mob" />
				<br class="hd-mob" />

				<div className={"w3-cell-row"}>
					<div className={"w3-container w3-mobile w3-cell w3-center"}>
						<h1 className={"question"}>Looking for my website?</h1>

						<Link to="/">
							<button
								className={
									"w3-button w3-black w3-opacity-min w3-large w3-text-white w3-border w3-border-white w3-round-large w3-ripple"
								}
							>
								Click Me!
							</button>
						</Link>
					</div>
					<div className={"w3-container w3-mobile w3-center w3-cell"}>
						<h1 className={"question"}>Looking for a wedding?</h1>

						<Link to="/wedding">
							<button
								className={
									"w3-button w3-black w3-opacity-min w3-large w3-text-white w3-border w3-border-white w3-round-large w3-ripple"
								}
							>
								Click Me!
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	</Background>
);

export default NotFoundPage;
